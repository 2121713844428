import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsInstance,
  RootState,
  StoreModules,
  StoreReduxAction,
  StoreReduxModels,
  UserReduxAction,
} from "gyg_common";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import { Spacing } from "gyg_common/components/styles/number";
import { PickupModalState } from "gyg_common/redux_store/order/models";

import { FlyingBurritoLoader } from "../shared/Loading/FlyingBurritoLoader/FlyingBurritoLoader";

import Restaurants from "@/views/components/Stores/Restaurants";

let storeSelected = false;

const Screen: React.FC<SetupScreenComponent<PickupModalState>> = ({
  state,
  onNext,
}) => {
  const dispatch = useDispatch();

  const { locationPermissionGranted, isBrazeInitialised } = useSelector(
    (state: RootState) => state.user
  );
  const { userConfirmedOrderSetup } = useSelector(
    (state: RootState) => state.order
  );
  const { menuStructure } = useSelector((state: RootState) => state.menu);
  const store = useSelector((s: RootState) => s.store);

  const setMessageToast = useCallback(
    (toastMessage: string | null) => {
      if (!store.error) {
        dispatch(
          UserReduxAction.setMessageToast(
            toastMessage === null
              ? null
              : {
                  showCloseButton: true,
                  title: toastMessage,
                }
          )
        );
      }
    },
    [dispatch, store.error]
  );

  const handleSetStore = useCallback(
    (payload: StoreReduxModels.Store) => {
      state.ChosenStore = payload;
      onNext(state);
    },
    [onNext, state]
  );

  const handleStoreSearch = useCallback(
    (payload: StoreReduxModels.StoreSearchPayload) =>
      dispatch(StoreReduxAction.storeSearch(payload)),
    [dispatch]
  );

  const handleGooglePlaceDetailSearchSuccess = useCallback(
    (payload: StoreReduxModels.SortStoreByDistanceProps) =>
      dispatch(StoreReduxAction.googlePlaceDetailSearchSuccess(payload)),
    [dispatch]
  );

  const handleSortStoresByDistance = useCallback(
    (payload: StoreReduxModels.SortStoreByDistanceProps) =>
      dispatch(StoreReduxAction.sortStoresByDistance(payload)),
    [dispatch]
  );

  const handleSetStoreSearchLocation = useCallback(
    (payload: StoreModules.StoreMapper.StoreCoordinates | null) =>
      dispatch(StoreReduxAction.setStoreSearchLocation(payload)),
    [dispatch]
  );

  const handleSetNearestStoreAsSelected = useCallback(
    (payload: StoreReduxModels.NearestStoreProps) => {
      dispatch(StoreReduxAction.setNearestStoreAsSelected(payload));
      if (store.stores.length > 0 && !state.ChosenStore && !storeSelected) {
        storeSelected = true;
        state.ChosenStore = store.stores[0];
        onNext(state);
      }
    },
    [dispatch, onNext, state, store.stores]
  );

  const handleSetStoreInfo = useCallback(
    (payload: StoreReduxModels.Store | null) =>
      dispatch(StoreReduxAction.setStoreInfo(payload)),
    [dispatch]
  );

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "restaurants",
        page_type: "restaurants_view",
      });
    }
  }, [isBrazeInitialised]);

  useEffect(() => {
    const onComponentUnmount = () => {
      setMessageToast(null);
    };

    return onComponentUnmount;
  }, [setMessageToast]);

  return store.getStoresLoading ? (
    <div
      style={{
        margin: Spacing.MediumThick,
        display: "flex",
        justifyContent: "center",
      }}>
      <FlyingBurritoLoader />
    </div>
  ) : (
    <Restaurants
      previousScreen={""}
      menuStructure={menuStructure}
      locationPermissionGranted={locationPermissionGranted}
      userConfirmedOrderSetup={userConfirmedOrderSetup}
      store={store}
      handleSetMessageToast={setMessageToast}
      handleStoreSearch={handleStoreSearch}
      handleGooglePlaceDetailSearchSuccess={
        handleGooglePlaceDetailSearchSuccess
      }
      handleSortStoresByDistance={handleSortStoresByDistance}
      handleSetStoreInfo={handleSetStoreInfo}
      handleSetStore={handleSetStore}
      handleSetStoreSearchLocation={handleSetStoreSearchLocation}
      handleSetNearestStoreAsSelected={handleSetNearestStoreAsSelected}
    />
  );
};

export default Screen;

import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { AnalyticsConst, AnalyticsInstance, RootState } from "gyg_common";
import deliveryWithUber from "gyg_common/components/assets/icons/services/delivery_with_uber_icon.png";
import deliveryIcon from "gyg_common/components/assets/icons/services/icon_delivery.png";
import pickupIcon from "gyg_common/components/assets/icons/services/icon_pickup.png";
import PrimaryYellowButton from "gyg_common/components/Buttons/PrimaryYellowButton";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import { Spacing } from "gyg_common/components/styles/number";
import {
  CollectionType,
  ModalState,
} from "gyg_common/redux_store/order/models";

const styles = StyleSheet.create({
  buttonContainer: {
    marginBottom: Spacing.Medium,
    marginHorizontal: Spacing.MediumThick,
  },
  mobile: {
    marginHorizontal: Spacing.Light,
  },
});

const OrderCollectionCategory: React.FC<SetupScreenComponent<ModalState>> = ({
  state,
  onChangeCollectionType,
}) => {
  const { t } = useTranslation();
  const { isTabletScreen } = useMediaQuery();

  const { locationPermissionGranted } = useSelector(
    (state: RootState) => state.user
  );
  const { stores } = useSelector((state: RootState) => state.store);

  const getNearestStoreIfLocationEnabled =
    locationPermissionGranted && stores.length > 0 ? stores[0] : null;

  const chosenStore = state.ChosenStore ?? getNearestStoreIfLocationEnabled;

  const onPickupPress = () => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.PickupSelection, {});
    onChangeCollectionType(CollectionType.PICK_UP, state, chosenStore);
  };

  const onDeliveryPress = () => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.DeliverySelection, {});
    onChangeCollectionType(CollectionType.DELIVERY);
  };

  return (
    <View>
      <View style={[styles.buttonContainer, !isTabletScreen && styles.mobile]}>
        <PrimaryYellowButton
          icon={pickupIcon as ImageSourcePropType}
          buttonName={t("OrderManagement:pickup")}
          fontSize={isTabletScreen ? 28 : 20}
          iconWidth={35}
          onClick={onPickupPress}
        />
      </View>
      <View style={[styles.buttonContainer, !isTabletScreen && styles.mobile]}>
        <PrimaryYellowButton
          icon={deliveryIcon as ImageSourcePropType}
          rightIcon={deliveryWithUber as ImageSourcePropType}
          buttonName={t("OrderManagement:delivery")}
          fontSize={isTabletScreen ? 28 : 19}
          iconWidth={45}
          rightIconWidth={isTabletScreen ? 120 : 90}
          onClick={onDeliveryPress}
        />
      </View>
    </View>
  );
};

export default OrderCollectionCategory;

import GoogleMapReact from "google-map-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { AnalyticsConst, AnalyticsInstance } from "gyg_common";
import {
  FormInput,
  PrimaryBlackButton,
  SearchTextInput,
} from "gyg_common/components";
import locationIcon from "gyg_common/components/assets/icons/icon_location.png";
import { Checkbox } from "gyg_common/components/Checkbox";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import ToFarAwayView from "gyg_common/components/OrderSetup/ToFarAwayView";
import { Spacing } from "gyg_common/components/styles/number";
import config from "gyg_common/config";
import { auDefault } from "gyg_common/modules/Store/utils";
import {
  DeliveryModalState,
  DropOffOption,
} from "gyg_common/redux_store/order/models";
import { getDistanceBetweenTwoPoints } from "gyg_common/utils";
import { getUserLocation } from "modules/location";
import { Typography } from "styles/typography";

import Marker from "../Stores/Marker";

import colours from "@/styles/colours";

const MAX_USER_DISTANCE_BETWEEN_SELECTED_LOCATION_IN_KM = 1;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: Spacing.Regular,
    paddingBottom: Spacing.Regular,
  },
  flex: { flex: 1 },
  scrollContentStyle: { flexGrow: 1 },
  addressText: {
    ...Typography.body,
    color: colours.black,
  },
  titleText: {
    ...Typography.headline,
    color: colours.black,
    bottom: Spacing.ExtraLight,
  },
  locationIcon: {
    height: Spacing.Regular,
    width: Spacing.Regular,
    marginRight: Spacing.Thin,
  },
  addressContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: Spacing.Light,
  },
  mapContainer: { height: 180, flex: 1 },
  pinCodeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    lineHeight: 1.5,
    marginBottom: 20,
  },
  pinCodeText: {
    ...Typography.bodyTwo,
    fontSize: 14,
  },
});

const Screen: React.FC<SetupScreenComponent<DeliveryModalState>> = ({
  state,
  onNext,
  onBack,
}) => {
  const { t } = useTranslation();
  const DropOffOptions = {
    [DropOffOption.MEET_AT_DOOR]: t("OrderManagement:MeetAtDoor"),
    [DropOffOption.LEAVE_AT_DOOR]: t("OrderManagement:LeaveAtDoor"),
  };

  const [apartment, setApartment] = useState<string>(state.Apartment ?? "");
  const [business, setBusiness] = useState<string>(state.Business ?? "");
  const [dropoffOption, setDropoffOption] = useState<DropOffOption>(
    state.DropOffOption ?? DropOffOption.MEET_AT_DOOR
  );
  const [dropoffPinCodeRequired, setDropoffPinCodeRequired] = useState<boolean>(
    state.DropoffPinCodeRequired ?? false
  );
  const [instructions, setInstructions] = useState<string>(
    state.Instructions ?? ""
  );
  const [
    userDistanceFromSelectedLocation,
    setUserDistanceFromSelectedLocation,
  ] = useState<number>(0);
  const shouldDisplayUserDistanceWarningModal =
    userDistanceFromSelectedLocation >
    MAX_USER_DISTANCE_BETWEEN_SELECTED_LOCATION_IN_KM;

  const handleOnDropOffOptionChange = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const dropOffOption =
      DropOffOption[e.currentTarget.value as keyof typeof DropOffOption];
    setDropoffOption(dropOffOption);
    if (dropOffOption === DropOffOption.LEAVE_AT_DOOR) {
      setDropoffPinCodeRequired(false);
    }
  };

  const getUserLocationAndCheckDistance = useCallback(() => {
    getUserLocation()
      .then((data) => {
        if (data && state.DeliveryAddress) {
          const distanceInKm = getDistanceBetweenTwoPoints(data.coords, {
            latitude: state.DeliveryAddress?.latitude,
            longitude: state.DeliveryAddress?.longitude,
          } as GeolocationCoordinates);
          setUserDistanceFromSelectedLocation(distanceInKm);
        } else {
          setUserDistanceFromSelectedLocation(0);
        }
      })
      .catch(() => {
        setUserDistanceFromSelectedLocation(0);
      });
  }, [state.DeliveryAddress]);

  useEffect(() => {
    AnalyticsInstance.trackView({
      page_name: AnalyticsConst.Events.DeliveryInfo,
      page_type: AnalyticsConst.Events.DeliveryInfo + "_view",
    });
  });
  useEffect(() => {
    getUserLocationAndCheckDistance();
  }, [getUserLocationAndCheckDistance]);

  const onConfirmUserAddressDistance = () => {
    setUserDistanceFromSelectedLocation(0);
  };

  return (
    <>
      {state.DeliveryAddress && (
        <ScrollView contentContainerStyle={styles.scrollContentStyle}>
          <View style={styles.container}>
            <View style={styles.flex}>
              <div>
                <SearchTextInput
                  placeHolder={t(
                    "OrderManagement:searchDeliveryAddressPlaceholder"
                  )}
                  onClear={() => {}}
                  onSearch={() => {}}
                  isFocused={false}
                  setFocused={onBack.bind(this, state)}
                  onChangeText={() => {}}
                  value={state.DeliveryAddress!.address}
                />
              </div>
              <div className='grey-line divider' />
              <div
                className='delivery-info-container'
                style={{
                  marginTop: Spacing.Regular,
                  marginBottom: Spacing.Regular,
                  columnGap: Spacing.Regular,
                }}>
                <div style={styles.flex}>
                  <div style={styles.mapContainer}>
                    <GoogleMapReact
                      defaultCenter={{
                        lat: auDefault.center[0],
                        lng: auDefault.center[1],
                      }}
                      defaultZoom={auDefault.zoom}
                      bootstrapURLKeys={{
                        key: config.googleMapsKey,
                        libraries: ["places"],
                        id: config.googleMapsScriptId,
                      }}
                      yesIWantToUseGoogleMapApiInternals
                      center={{
                        lat: state.DeliveryAddress?.latitude,
                        lng: state.DeliveryAddress?.longitude,
                      }}
                      zoom={18}>
                      <Marker
                        id={0}
                        lat={state.DeliveryAddress.latitude}
                        lng={state.DeliveryAddress.longitude}
                        selected={false}
                      />
                    </GoogleMapReact>
                  </div>
                  <View style={styles.addressContainer}>
                    <Image
                      resizeMode='contain'
                      source={locationIcon as ImageSourcePropType}
                      style={styles.locationIcon}
                    />
                    <Text style={styles.addressText}>
                      {state.DeliveryAddress!.address}
                    </Text>
                  </View>
                </div>
                <div style={styles.flex}>
                  <FormInput
                    name={t("OrderManagement:aptSuiteFlor")}
                    handleChangeText={setApartment}
                    error={false}
                    value={apartment}
                    placeHolder={t("OrderManagement:optional")}
                    errorMsg={""}
                    isDisablePadding
                  />
                  <FormInput
                    name={t("OrderManagement:businessBuildingName")}
                    handleChangeText={setBusiness}
                    error={false}
                    value={business}
                    placeHolder={t("OrderManagement:optional")}
                    errorMsg={""}
                  />

                  <Text style={styles.titleText}>
                    {t("OrderManagement:dropOffOptions")}
                  </Text>

                  <div className='horizontal-radio-button-list'>
                    <input
                      type='radio'
                      name='DropOffOptions'
                      id='MeetAtDoor'
                      value={DropOffOption.MEET_AT_DOOR}
                      checked={dropoffOption === DropOffOption.MEET_AT_DOOR}
                      onChange={handleOnDropOffOptionChange}
                    />
                    <label htmlFor='MeetAtDoor'>
                      {DropOffOptions[DropOffOption.MEET_AT_DOOR]}
                    </label>
                    <input
                      type='radio'
                      name='DropOffOptions'
                      id='LeaveAtDoor'
                      value={DropOffOption.LEAVE_AT_DOOR}
                      checked={dropoffOption === DropOffOption.LEAVE_AT_DOOR}
                      onChange={handleOnDropOffOptionChange}
                    />
                    <label htmlFor='LeaveAtDoor'>
                      {DropOffOptions[DropOffOption.LEAVE_AT_DOOR]}
                    </label>
                  </div>

                  {dropoffOption === DropOffOption.MEET_AT_DOOR && (
                    <div style={styles.pinCodeContainer}>
                      <Text style={styles.pinCodeText}>
                        {t("OrderManagement:dropoffPinCodeRequired")}
                      </Text>
                      <TouchableOpacity
                        activeOpacity={1}
                        onPress={() =>
                          setDropoffPinCodeRequired(!dropoffPinCodeRequired)
                        }>
                        <Checkbox selected={dropoffPinCodeRequired} />
                      </TouchableOpacity>
                    </div>
                  )}

                  <FormInput
                    name={t("OrderManagement:deliveryInstructions")}
                    handleChangeText={setInstructions}
                    error={false}
                    value={instructions}
                    placeHolder={t("OrderManagement:optional")}
                    errorMsg={""}
                    isDisablePadding
                  />
                </div>
              </div>
            </View>
            <PrimaryBlackButton
              onClick={() => {
                onNext({
                  ...state,
                  Apartment: apartment,
                  Business: business,
                  DropOffOption: dropoffOption,
                  DropOffOptionTitle: DropOffOptions[dropoffOption],
                  DropoffPinCodeRequired: dropoffPinCodeRequired,
                  Instructions: instructions,
                });
              }}
              buttonName={t("OrderManagement:confirm")}
            />

            {shouldDisplayUserDistanceWarningModal && (
              <ToFarAwayView
                userDistanceFromSelectedLocation={
                  userDistanceFromSelectedLocation
                }
                onChangeAddress={onBack.bind(this, state)}
                onConfirm={onConfirmUserAddressDistance}
              />
            )}
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default Screen;

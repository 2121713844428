import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  View,
} from "react-native";
import { useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  RootState,
} from "gyg_common";
import { IconButton, PrimaryBlackButton } from "gyg_common/components";
import editIcon from "gyg_common/components/assets/icons/icon_edit.png";
import deliveryIcon from "gyg_common/components/assets/icons/services/icon_delivery.png";
import TertiaryButton from "gyg_common/components/Buttons/TertiaryButton";
import {
  AbandonOrderModal,
  enableAbandonOrderModal,
} from "gyg_common/components/OrderSetup/AbandonOrderModal";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import { Spacing } from "gyg_common/components/styles/number";
import { useMediaQuery } from "gyg_common/hooks/useMediaQuery";
import {
  CollectionType,
  DeliveryModalState,
  DropOffOption,
} from "gyg_common/redux_store/order/models";

import colours from "@/styles/colours";

const textStyles = (isDesktop: boolean) => {
  return StyleSheet.create({
    orderTypeText: {
      fontFamily: "Sini-Bold",
      fontSize: isDesktop ? 28 : 18,
      lineHeight: 1,
    },
  });
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: Spacing.Regular,
    paddingBottom: Spacing.Regular,
  },
  contentContainer: {
    flex: 1,
    paddingBottom: Spacing.Regular,
  },
  orderTypeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowCentered: { flexDirection: "row", alignItems: "center" },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colours.yellow,
    borderRadius: 100,
    width: 40,
    height: 40,
    marginRight: 10,
  },
  icon: { width: 30, height: 30 },
  infoContainer: {
    marginTop: Spacing.Regular,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  infoBoxContainer: { display: "flex", flexDirection: "column", rowGap: 10 },
  infoTitleText: {
    fontSize: 18,
    fontFamily: "Sini-Bold",
  },
  infoDescriptionText: {
    fontSize: 14,
    fontFamily: "Montserrat-Regular",
  },
});

const Screen: React.FC<SetupScreenComponent<DeliveryModalState>> = ({
  state,
  onBack,
  onBackToScreen,
  onChangeCollectionType,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { items } = useSelector((state: RootState) => state.cart);
  const analyticsState = useSelector((s: RootState) => s.analytics);
  const [abandonOrderModalVisible, setAbandonOrderModalVisible] =
    useState(false);

  const { isDesktopScreen } = useMediaQuery();
  const isWebDesktop = Platform.OS === "web" && isDesktopScreen;

  const onSwitchToPickUp = () => {
    //Check if basket is empty or not
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.ChangeToPickup,
      AnalyticsPayloadGenerator.storePayload(true, analyticsState)
    );
    if (items.length === 0 || !enableAbandonOrderModal) {
      onChangeCollectionType(CollectionType.PICK_UP, undefined, {
        ...state.ChosenStore,
      });
    } else {
      setAbandonOrderModalVisible(true);
    }
  };

  const onAbandonModalClose = () => {
    setAbandonOrderModalVisible(false);
  };

  const addressText =
    state.ChosenStore?.address1?.length > 0
      ? state.ChosenStore?.address1
      : state.ChosenStore?.address2;

  const addressForDelivery = [
    ...(state.Business ? [state.Business] : []),
    [
      ...(state.Apartment ? [state.Apartment] : []),
      ...(state.DeliveryAddress?.address
        ? [state.DeliveryAddress?.address]
        : []),
    ].join(" / "),
  ].join("<br />");

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <div style={styles.orderTypeContainer}>
          <View style={styles.rowCentered}>
            <View style={styles.iconContainer}>
              <Image
                source={deliveryIcon as ImageSourcePropType}
                style={styles.icon}
                resizeMode='contain'
              />
            </View>
            <div style={textStyles(isWebDesktop).orderTypeText}>
              {t("OrderManagement:delivery").toUpperCase()}
            </div>
          </View>
          <div>
            <TertiaryButton
              onClick={onSwitchToPickUp}
              title={t("OrderManagement:changeToPickup")}
              bgColor={colours.borderGrey}
            />
          </div>
        </div>
        <div className='grey-line divider' />
        <div style={styles.infoContainer}>
          <div style={styles.infoBoxContainer}>
            <div style={styles.infoTitleText}>
              {t("OrderManagement:deliveryDetails")}
            </div>
            <div
              style={styles.infoDescriptionText}
              dangerouslySetInnerHTML={{ __html: addressForDelivery }}
            />
            <div style={styles.infoDescriptionText}>
              {state.DropOffOptionTitle}
              {state.DropOffOption === DropOffOption.MEET_AT_DOOR &&
                state.DropoffPinCodeRequired && (
                  <>
                    <br />
                    {t("OrderManagement:dropoffPinCodeRequired")}
                  </>
                )}
              {state.Instructions && (
                <>
                  <br />
                  {`${state.Instructions}`}
                </>
              )}
            </div>
          </div>
          <IconButton
            withShadow={false}
            onPress={() => {
              onBackToScreen("DeliveryAddress");
            }}
            iconImage={editIcon}
          />
        </div>
        <div className='grey-line divider' />
        <div style={styles.infoContainer}>
          <div style={styles.infoBoxContainer}>
            <div style={styles.infoTitleText}>
              {t("OrderManagement:gygRestaurant")}
            </div>
            <div style={styles.infoDescriptionText}>{addressText}</div>
          </div>
          <div>
            <IconButton
              withShadow={false}
              onPress={() => {
                onBack(state);
              }}
              iconImage={editIcon}
            />
          </div>
        </div>
      </View>
      <PrimaryBlackButton
        onClick={onSubmit.bind(this, state)}
        buttonName={t("OrderManagement:confirm")}
      />

      <AbandonOrderModal
        isModalVisible={abandonOrderModalVisible}
        isDelivery={true}
        abandonOrder={onChangeCollectionType.bind(
          this,
          CollectionType.PICK_UP,
          undefined,
          { ...state.ChosenStore }
        )}
        keepCart={onAbandonModalClose}
        onModalClose={onAbandonModalClose}
      />
    </View>
  );
};

export default Screen;

import { Platform, PlatformOSType } from "react-native";

import { locales } from "../locales/locales.enum";
import DeviceInfo from "../utils/deviceInfo";
import UUIDGenerator from "../utils/uuidGenerator";
import getConfig, { AppConfig } from "./getConfig";
import type { WebConfig } from "./getConfig.web";

export interface ConfigSettings {
  api: { host: string; signin: string; timeout: number; region: Region };
  sessionId: string;
  grantType: string;
  accessToken: string;
  refreshToken: string;
  token: string;
  orderListLimit: number;
  version: locales;
  appVersion: string;
  platform: PlatformOSType;
  googleMapsKey: string;
  googleMapsScriptId: string;
  visualTestingMode: boolean;
  growthBook: { apiHost: string; clientKey: string };
}

export enum Region {
  AU = "AU",
  US = "US",
  SG = "SG",
}

const getPlatform = (platform: PlatformOSType) => {
  if (platform === "macos" || platform === "windows") return "web";
  return platform;
};

const appOrWebConfig = getConfig();
const version =
  (appOrWebConfig as AppConfig).VERSION ||
  (appOrWebConfig as unknown as WebConfig).REACT_APP_VERSION;
const googleMapsKey =
  Platform.OS === "ios"
    ? (appOrWebConfig as AppConfig).REACT_APP_GOOGLE_MAPS_KEY_IOS
    : Platform.OS === "android"
      ? (appOrWebConfig as AppConfig).REACT_APP_GOOGLE_MAPS_KEY
      : (appOrWebConfig as unknown as WebConfig).REACT_APP_GOOGLE_MAPS_KEY;

const codePushVersion =
  Platform.OS === "android"
    ? (appOrWebConfig as AppConfig).REACT_APP_CODEPUSH_RELEASE_VERSION_ANDROID
    : (appOrWebConfig as AppConfig).REACT_APP_CODEPUSH_RELEASE_VERSION_IOS;

const growthBook =
  Platform.OS === "web"
    ? {
        apiHost: (appOrWebConfig as unknown as WebConfig)
          .REACT_APP_GROWTHBOOK_API_HOST,
        clientKey: (appOrWebConfig as unknown as WebConfig)
          .REACT_APP_GROWTHBOOK_CLIENT_KEY,
      }
    : {
        apiHost: (appOrWebConfig as AppConfig).GROWTHBOOK_API_HOST,
        clientKey: (appOrWebConfig as AppConfig).GROWTHBOOK_CLIENT_KEY,
      };

const config: ConfigSettings = {
  api: {
    host: process.env.BASE_URL || process.env.REACT_APP_BASE_URL || "",
    signin: process.env.REACT_APP_SIGN_URL,
    timeout: 60000,
    region: (process.env.REACT_APP_REGION as Region) || Region.AU,
  },
  appVersion: `${DeviceInfo.getVersion()}.${codePushVersion || 0}`,
  sessionId: UUIDGenerator.getRandomUUID(),
  grantType: "password",
  accessToken: "",
  refreshToken: "",
  token: "",
  orderListLimit: 10,
  version: version as locales,
  platform: getPlatform(Platform.OS),
  googleMapsKey,
  googleMapsScriptId: "__googleMapsScriptId",
  visualTestingMode: process.env.VRT_MODE === "true" ? true : false,
  growthBook,
};

export default config;

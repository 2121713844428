/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction } from "redux";
import * as Effects from "redux-saga/effects";
import { fork, put, takeLatest } from "redux-saga/effects";

import config from "../../config";
import translationAU from "../../locales/LoginRegistration/au.json";
import translationUS from "../../locales/LoginRegistration/us.json";
import { mapCurrentUserToUserProfile } from "../../modules/User/utils";
import * as ApiService from "../../services/api/auth";
import {
  ForgotPasswordResponse,
  LogoutAllResponse,
} from "../../services/api/auth/models";
import { cartActions } from "../cart/cart.slice";
import { CheckoutAction } from "../checkout/checkout.slice";
import { ErrorResponse } from "../error/models";
import { buildErrorResponse } from "../error/utils";
import { favouriteActions } from "../favourite/favourite.slice";
import { loyaltyActions } from "../loyalty/loyalty.slice";
import { orderActions } from "../order/order.slice";
import { socialActions } from "../social/social.slice";
import { userActions } from "../user/user.slice";
import { authActions, AuthActionsType } from "./auth.slice";
import { CurrentUser, LoginOTPChallenge } from "./models";

const call: any = Effects.call;

//save token to config
const saveToken = (currentUser: CurrentUser) => {
  config.accessToken = currentUser.accessToken;
  config.refreshToken = currentUser.refreshToken;
};

export function* handleLogin(
  action: AuthActionsType
): Generator<any, void, CurrentUser> {
  try {
    const result: CurrentUser | LoginOTPChallenge = yield call(
      ApiService.login,
      action.payload
    );

    if (ApiService.isOTPChallenge(result)) {
      yield put(authActions.setRequiresOTP({ mfaToken: result.mfaToken }));
    } else {
      yield put(authActions.loginSuccess(result));
      if (!result.verifiedMobileNumber) {
        yield put(socialActions.setSocialRegister(true));
        yield put(userActions.setShowMobileVerifyModal(true));
      }
    }
  } catch (e) {
    yield put(
      authActions.loginError(
        buildErrorResponse(e, {
          au: translationAU.loginUnexpectedError,
          us: translationUS.loginUnexpectedError,
        })
      )
    );
  }
}

export function* watchLogin(): Generator<Effects.ForkEffect<never>, void> {
  yield takeLatest(authActions.login.type, handleLogin);
}

//Get all user's data (loyalty, user profile and rewards) upon successful login
export function* handleLoginSuccess(
  action: AuthActionsType
): Generator<any, void> {
  const payload = action.payload as CurrentUser;
  saveToken(payload);

  yield put(orderActions.clearOrderResponse());
  yield put(orderActions.clearOrderError());

  //set initial user state
  yield put(userActions.setUserProfile(mapCurrentUserToUserProfile(payload)));
  yield put(CheckoutAction.setSavePayment(payload.storeCard));

  yield put(userActions.getUserProfile());
  yield put(loyaltyActions.getUserLoyalty());
  yield put(loyaltyActions.getUserRewards());
}

export function* watchLoginSuccess(): Generator<
  Effects.ForkEffect<never>,
  void
> {
  yield takeLatest(authActions.loginSuccess.type, handleLoginSuccess);
}

//Get user's data upon successful registration
export function* handleRegistrationSuccess(): Generator<any, void> {
  yield put(userActions.getUserProfile());
}

export function* watchRegistrationSuccess(): Generator<
  Effects.ForkEffect<never>,
  void
> {
  yield takeLatest(authActions.registerSuccess.type, handleRegistrationSuccess);
}

//Clear Token in config folder
export function* handleUnsetToken(): Generator<unknown, void> {
  try {
    config.accessToken = "";
    config.refreshToken = "";
    yield put(authActions.setTokenStatus(false));
  } catch (e) {
    yield put(authActions.setTokenStatus(false));
  }
}

export function* watchUnsetToken(): Generator<Effects.ForkEffect<never>, void> {
  yield takeLatest(authActions.unsetToken.type, handleUnsetToken);
}

//Clear Token in config folder
export function* handleSetCurrentUserToState(
  action: AuthActionsType
): Generator<unknown, void> {
  try {
    yield call(saveToken, action.payload);
  } catch (e) {
    yield put(authActions.setTokenStatus(false));
  }
}

export function* watchSetCurrentUserToState(): Generator<
  Effects.ForkEffect<never>,
  void
> {
  yield takeLatest(
    authActions.setCurrentUserToState.type,
    handleSetCurrentUserToState
  );
}

export function* handleClearUserData(): Generator<unknown, void> {
  yield put(loyaltyActions.updateRedeemDollars(null));
  yield put(userActions.clearUserProfile());
  yield put(socialActions.clearSocialState());
  yield put(cartActions.clearCart());
  yield put(orderActions.setOrderResponse(null));
  yield put(orderActions.clearOrderResponse());
  yield put(favouriteActions.clearFavourite());
  yield put(loyaltyActions.clearUserRewards());
  yield put(CheckoutAction.setPaymentResponse(null));
  yield put(authActions.unsetToken());
  yield put(loyaltyActions.clearUserRewards());
  yield put(loyaltyActions.clearActiveReward());
  yield put(loyaltyActions.setUserCoffeeLoyalty(null));
  yield put(loyaltyActions.setUserLoyaltyToState(null));
}

export function* handleLogoutFromCurrentDevice(): Generator<unknown, void> {
  yield* handleClearUserData();
}

export function* watchLogoutFromCurrentDevice(): Generator<
  Effects.ForkEffect<never>,
  void
> {
  yield takeLatest(
    authActions.logoutCurrentDevice.type,
    handleLogoutFromCurrentDevice
  );
}

export function* handleLogoutFromAllDevices(): Generator<
  unknown,
  void,
  LogoutAllResponse
> {
  try {
    yield call(ApiService.logoutAll);
    yield put(authActions.logoutAllDevicesSuccess());
    yield* handleClearUserData();
  } catch (e) {
    yield put(authActions.logoutAllDevicesError(buildErrorResponse(e)));
  }
}

export function* watchLogoutFromAllDevices(): Generator<
  Effects.ForkEffect<never>,
  void
> {
  yield takeLatest(
    authActions.logoutAllDevices.type,
    handleLogoutFromAllDevices
  );
}

export function* handleForgotPassword(
  action: AuthActionsType
): Generator<
  Effects.CallEffect<ForgotPasswordResponse> | Effects.PutEffect<AnyAction>,
  void
> {
  try {
    yield call(ApiService.forgotPassword, action.payload);
    yield put(authActions.forgotPasswordSuccess());
  } catch (e) {
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(authActions.forgotPasswordError(errorResponse));
  }
}

export function* watchForgotPassword() {
  yield takeLatest(authActions.forgotPassword.type, handleForgotPassword);
}

export function* handleResetPassword(
  action: AuthActionsType
): Generator<unknown, void> {
  try {
    yield call(ApiService.resetPassword, action.payload);
    yield put(authActions.resetPasswordSuccess());
  } catch (e) {
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(authActions.resetPasswordError(errorResponse));
  }
}

export function* watchResetPassword() {
  yield takeLatest(authActions.resetPassword.type, handleResetPassword);
}

export function* handleVerifyLoginOTP(
  action: AuthActionsType
): Generator<unknown, void> {
  yield put(authActions.setOTPError(null));
  yield put(authActions.setOTPSubmittedLoading(true));
  try {
    const result: CurrentUser = yield call(
      ApiService.verifyLoginOTPCode,
      action.payload
    );

    yield put(authActions.loginSuccess(result));
    yield put(authActions.setDoesNotRequireOTP());
    if (!result.verifiedMobileNumber) {
      yield put(socialActions.setSocialRegister(true));
      yield put(userActions.setShowMobileVerifyModal(true));
    }
  } catch (e) {
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(authActions.setOTPError(errorResponse));
  } finally {
    yield put(authActions.setOTPSubmittedLoading(false));
  }
}

export function* watchVerifyLoginOTP() {
  yield takeLatest(authActions.verifyLoginOTP.type, handleVerifyLoginOTP);
}

const saga = [
  fork(watchLogin),
  fork(watchLoginSuccess),
  fork(watchRegistrationSuccess),
  fork(watchUnsetToken),
  fork(watchSetCurrentUserToState),
  fork(watchLogoutFromCurrentDevice),
  fork(watchLogoutFromAllDevices),
  fork(watchForgotPassword),
  fork(watchResetPassword),
  fork(watchVerifyLoginOTP),
];

export default saga;
